import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import googleIcon from "../assets/img/google-icon.svg";
import sunLifeIcon from "../assets/img/sun-life-logo.png";
import otCareIcon from "../assets/img/ot-care-logo.svg";
import securianIcon from "../assets/img/securian-logo.png";
import cobIcon from "../assets/img/cob-logo.png";
import blueprintIcon from "../assets/img/blueprint-logo.png";
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>
                                Experience
                            </h2>
                            <p>I have interned at and worked with a plethora of companies and organizations.</p>
                            <Carousel 
                            responsive={responsive} 
                            infinite={true} 
                            className="skill-slider"
                            centerMode={false}
                            autoPlay={true}
                            autoPlaySpeed={2000}>
                                <div className="item">
                                    <img src={googleIcon} alt="Image" />
                                    <h5>Google</h5>
                                </div>
                                <div className="item">
                                    <img src={sunLifeIcon} alt="Image" />
                                    <h5>SunLife Financial</h5>
                                </div>
                                <div className="item">
                                    <img src={otCareIcon} alt="Image" />
                                    <h5>Innovative OT Care</h5>
                                </div>
                                <div className="item">
                                    <img src={securianIcon} alt="Image" />
                                    <h5>Securian Canada</h5>
                                </div>
                                <div className="item">
                                    <img src={cobIcon} alt="Image" />
                                    <h5>City of Brampton</h5>
                                </div>
                                <div className="item">
                                    <img src={blueprintIcon} alt="Image" />
                                    <h5>UofT Blueprint</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} />
        </section>
    )
}