import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/VisualSorter.png";
import projImg2 from "../assets/img/StreetFighter.png";
import projImg3 from "../assets/img/Insurance.png";
import projImg4 from "../assets/img/LinearRegression.png";
import projImg5 from "../assets/img/Knight.png";
import projImg6 from "../assets/img/WordSearchSolver.png";

import projImgGesture from "../assets/img/Gesture.png"
import projImgEmotion from "../assets/img/Emotion.png"
import projImgFlick from "../assets/img/Movies.png";

import projTennis from "../assets/img/Tennis.png";

import projImg7 from "../assets/img/TicTacToe.png";
import projImg8 from "../assets/img/Digit.png";
import projImg9 from "../assets/img/Maze.png";
import projImg10 from "../assets/img/Minesweeper.png";
import projImg11 from "../assets/img/Hospital.png";

import projImg12 from "../assets/img/UW.png";
import projImg13 from "../assets/img/Pagerank.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

import projMastermind from "../assets/img/Mastermind.png";

export const Projects = () => {

    const softwareProjects = [
        {
            title: "EasyClaim",
            description: "Proof-of-Concept for Securian Canada",
            imgUrl: projImg3,
            index: 2
        },
        {
            "title": "Brampton Tennis Queue",
            "description": "Dynamic Queue System for Brampton Tennis Courts",
            imgUrl: projTennis,
            index: 16
        },
        {
            title: "Visual Sorter",
            description: "Visually Demonstrates Sorting Algorithms in Action",
            imgUrl: projImg1,
            index: 0
        },
        {
            title: "StreetFighter",
            description: "Java Implementation of the StreetFighter Game",
            imgUrl: projImg2,
            index: 1
        },
        {
            title: "FlickFindr",
            description: "A Movie Recommendation System",
            imgUrl: projImgFlick,
            index: 15
        },
        {
            title: "Knight's Tour Puzzle",
            description: "Solves the Puzzle Using Recursion",
            imgUrl: projImg5,
            index: 4
        },
        {
            title: "Word Search Solver",
            description: "Visually Solves a Given Wordsearch",
            imgUrl: projImg6,
            index: 5
        },
        {
            title: "Linear Regression",
            description: "Linear Regression Using Binary Search",
            imgUrl: projImg4,
            index: 3
        }
    ];

    const aiProjects = [
        {
            title: "Mastermind A.I.",
            description: "A.I. Player for the Mastermind Colour Puzzle",
            imgUrl: projMastermind,
            index: 17
        },
        {
            title: "Emotion Detection",
            description: "A CNN Model that Detects the Emotion on Faces",
            imgUrl: projImgEmotion,
            index: 7
        },
        {
            title: "Written Digit Recognition",
            description: "Recognizes Written Digits Using a Neural Network",
            imgUrl: projImg8,
            index: 8
        },
        {
            title: "Tic Tac Toe A.I.",
            description: "A.I. Player for the Tic Tac Toe Game",
            imgUrl: projImg7,
            index: 9
        },
        {
            title: "Gesture Recognition",
            description: "A Model that Recognizes Gestures",
            imgUrl: projImgGesture,
            index: 6
        },
        {
            title: "Minesweeper A.I.",
            description: "A.I. Player for the Minesweeper Game",
            imgUrl: projImg10,
            index: 11
        },
        {
            title: "Hospital Optimization",
            description: "Optimizes the Placement of Hospitals in a Randomly Generated City",
            imgUrl: projImg11,
            index: 12
        },
        {
            title: "Maze Solver",
            description: "Visually Solves a Given Maze",
            imgUrl: projImg9,
            index: 10
        },
    ]

    const otherProjects = [
        {
            title: "UW - POTW",
            description: "Automated Solution for UW's Problem of the Week",
            imgUrl: projImg12,
            index: 13
        },
        {
            title: "Pagerank Algorithm",
            description: "Python Implementation of the Pageranking Algorithm",
            imgUrl: projImg13,
            index: 14
        },
    ]

    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                        <p>Here are some of my past coding projects:</p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Software</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">AI / ML</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Other</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            softwareProjects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            aiProjects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Row>
                                        {
                                            otherProjects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2} />
        </section>
    )
}