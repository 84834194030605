import { Col } from "react-bootstrap";

const LINKS = [
    "https://github.com/Ron-Madan/Portfolio/tree/main/Visual-Sorter",
    "https://github.com/Ron-Madan/Portfolio/tree/main/StreetFighter",
    "https://github.com/Ron-Madan/Easyclaim",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Linear%20Regression%20-%20Self%20Implementation",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Knight's%20Tour%20Puzzle",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Word%20Search%20Solver",

    "https://github.com/Ron-Madan/Portfolio/tree/main/Gesture%20Recognition",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Emotion%20Recognition",

    "https://github.com/Ron-Madan/Portfolio/tree/main/Written-Digit-Recognition",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Tic%20Tac%20Toe%20AI",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Maze%20Solver",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Minesweeper",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Hospital%20Optimization",

    "https://github.com/Ron-Madan/Portfolio/tree/main/UW%20Problem%20of%20the%20Week",
    "https://github.com/Ron-Madan/Portfolio/tree/main/Pagerank%20Algorithm",
    "https://github.com/Ron-Madan/Portfolio/tree/main/FlickFindr",

    "https://github.com/uoftblueprint/brampton-tennis-queue",
    "https://github.com/Ron-Madan/Portfolio/tree/main/mastermind-game"
]

export const ProjectCard = ({ title, description, imgUrl, index }) => {
    console.log(title)
    return (
        <Col sm={6} md={4}>
            <div className="proj-imgbx">
                <img src={imgUrl} />
                <div className="proj-txtx">
                    <a href={LINKS[index]} target="_blank">
                    <h4>{title}</h4>
                    </a>
                    <span>{description}</span>
                </div>
            </div>
        </Col>
    )
}